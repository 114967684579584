import React from "react"
import PropTypes from "prop-types"
import Layout from "../components/Layout/Layout"
import GlobalContext from "../context/GlobalContext"
import { setDataLayer } from "../helpers/dataLayerSetter"
import SchemaSnippet from "../components/SchemaSnippet/SchemaSnippet"
import PDPIntro from "../components/PDPIntro/PDPIntro"
import SimilarRecipes from "../components/SimilarRecipes/SimilarRecipes"
import SimilarProducts from "../components/SimilarProducts/SimilarProducts"
import { gTagScriptConsent } from "../components/Scripts/Gtm.Head"

const Page = ({ pageContext }) => {
    const {
        globalComponents,
        seoData,
        pageType,
        dataLayer,
        globalLabels,
        pageLanguage,
        pageTranslationInfo,
        pageApiData,
    } = pageContext

    setDataLayer(dataLayer, seoData, pageType)
    const { product, similarProducts, relatedRecipes, schemaSnippet } = pageApiData
    const {
        productsTitle = "Similar products",
        recipesTitle = "Recipes",
        recipesCta,
        minutesLabel = "minutes",
        goBackLabel = "Go back",
    } = globalLabels?.pdp || {}
    const cta = {
        label: recipesCta?.label || "See all the recipes",
        url: recipesCta?.url || "/nl-be/rlp/",
        gaEventClass: recipesCta?.gaEventClass || "",
        gaEventLabel: recipesCta?.gaEventLabel || "",
    }

    return (
        <GlobalContext labels={globalLabels}>
            <Layout
                seoData={seoData}
                globalComponents={globalComponents}
                pageLanguage={pageLanguage}
                pageTranslationInfo={pageTranslationInfo}
            >
                <SchemaSnippet snippet={schemaSnippet} />
                <PDPIntro goBackLabel={goBackLabel} {...product} />
                <SimilarProducts products={similarProducts} title={productsTitle} />
                <SimilarRecipes
                    similarRecipes={relatedRecipes}
                    title={recipesTitle}
                    cta={cta}
                    minutesLabel={minutesLabel}
                />
            </Layout>
        </GlobalContext>
    )
}
export const Head = () => {
    const dataObject = typeof window !== "undefined" ? window?.gtmDataLayer : {}
    return (
        <>
            <script
                id="first-unique-id"
                type="text/javascript"
                dangerouslySetInnerHTML={{
                    __html: `var MdlzdataLayer = ${JSON.stringify(dataObject)};`,
                }}
            />
            <script async type="text/javascript" dangerouslySetInnerHTML={{ __html: gTagScriptConsent }} />
        </>
    )
}

Page.propTypes = {
    pageContext: PropTypes.shape({
        globalComponents: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
        pageType: PropTypes.string,
        dataLayer: PropTypes.object,
        seoData: PropTypes.shape({
            title: PropTypes.string,
            description: PropTypes.string,
            ogTitle: PropTypes.string,
            ogDescription: PropTypes.string,
            ogType: PropTypes.string,
            twitterTitle: PropTypes.string,
            twitterDescription: PropTypes.string,
            canonicalLink: PropTypes.string,
            metaTitle: PropTypes.string,
            featuredImage: PropTypes.shape({
                url: PropTypes.string,
            }),
        }),
        globalLabels: PropTypes.object,
        pageLanguage: PropTypes.string,
        pageTranslationInfo: PropTypes.oneOfType([PropTypes.object, PropTypes.oneOf([null])]),
        pageApiData: PropTypes.shape({
            product: PropTypes.shape(),
            similarProducts: PropTypes.arrayOf(PropTypes.any),
            relatedRecipes: PropTypes.arrayOf(PropTypes.any),
            schemaSnippet: PropTypes.string,
        }),
    }),
}

export default Page
